import React  from 'react';
import {Theme} from "./Theme";
import {App} from "./App";
import {Example} from "./components/Example";
import {Ratings} from "./components/Rating";
import styled from 'styled-components'

import { ContextProvider } from "./components/Context";
import { ColorObject } from "./models/color-object";

const Container =styled.div`
  display: flex;
  flex-direction: column;
	margin-bottom: 2rem;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`

const Info =styled.div`
  display: flex;
	flex-direction: column;

  @media (min-width: 900px) {
    margin-left: 1rem;
  }
`


export const Footer = styled.div`
  color: ${({theme}) => theme.title};
  font-weight: bold;
	font-size: 1em;
  text-align: center;
  margin: 0 auto;
	padding-bottom: 5rem;
	
  max-width: 54rem;
	border: 2rem;
  span {
    color: #0091FF;
  }

	@media (max-width: 900px) {
		display: none;
	}
`

export const Main: React.FC = () => {

	const initalColorState: ColorObject =  {
		color_one: "B2F0F2",
		color_two: "001D2E",
		contrast: '13.7'
	}
	const [colorObject, setColorObject] = React.useState(initalColorState);

	return (
		<>
			<Theme>
				<ContextProvider.Provider value={{colorObject, setColorObject}}>
					<Container>
						<App/>
						<Info>
							<Ratings contrast={colorObject.contrast ? colorObject.contrast : '1'}/>
							<Example color_one={"#" + colorObject.color_one} color_two={"#" + colorObject.color_two}/>
						</Info>
					</Container>
				</ContextProvider.Provider>
			</Theme>
			<Footer>
				Feito com ❤️ por <span>Lenderson Macedo</span>
			</Footer>
		</>

	);
};
