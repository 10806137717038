export const darkTheme = {
	title: 'dark',
	background: "#1D2630",
	text: "#EEF1F4",
	color: {
		colorPickerBackground: 'rgba(255,255,255,.11)',
		colorPickerMainText: 'rgba(0,0,0,1)',
		colorPickerPlaceholder: 'rgba(255,255,255,.4)',
		colorPickerHashtag: 'rgba(168,180,193,.5)',
		toogleBackground: 'rgba(255,255,255,.11)',
		tooglePositive: '#0091FF',
	}
}
