import React, {useState} from 'react';
import { ColorPicker, Button, Wrapper } from "./style";
import { TextField } from "./components/TextField";
import {useColor} from "./components/Context";
import {GetContrast} from "../data/usecases/get-contrast/get-contrast";
import {HexToColor} from "../data/Helper/hex-to-color/hex-to-color";


export const App: React.FC = () => {
	const { colorObject, setColorObject } = useColor()
	const { checker } = new GetContrast()
	const [color1, setColor1] = useState(colorObject.color_one);
	const [color2, setColor2] = useState(colorObject.color_two);

	const validate = (e: string, fn: () => void): void => {
		if (/^#?([0-9A-F]{3}){1,2}$/i.test(e)) {
			fn()
		}
	}
	const HandlerColorOne = (e: string) => {
		setColor1(e)

		validate(e, () => {
			setColorObject({...colorObject, color_one: e, contrast: checker( HexToColor.convert(e), HexToColor.convert(colorObject.color_two)) })
		})

		if (e === '') {
			setColorObject({...colorObject, color_one: 'FFFFFF', contrast: checker( HexToColor.convert('FFFFFF'), HexToColor.convert(colorObject.color_two)) })
		}
	}

	const HandlerColorTwo = (e: string) => {
		setColor2(e)
		validate(e, () => {
			setColorObject({...colorObject, color_two: e, contrast: checker( HexToColor.convert(e), HexToColor.convert(colorObject.color_one)) })
		})

		if (e === '') {
			setColorObject({...colorObject, color_two:'FFFFFF', contrast: checker( HexToColor.convert('FFFFFF'), HexToColor.convert(colorObject.color_one)) })
		}
	}

	return (

			<ColorPicker>
				<Wrapper>
					<TextField
						color={color1}
						name={'Cor do Texto'}
						onChange={HandlerColorOne}
					/>
					<TextField
						name={'Cor do Fundo'}
						color={color2}
						onChange={HandlerColorTwo}
					/>
					<Button onClick={() => {
						let aux = color1
						setColor1(color2)
						setColor2(aux)
						setColorObject({ ...colorObject, 	color_one: colorObject.color_two, color_two: colorObject.color_one})
					}}> Inverter Cores </Button>
				</Wrapper>
			</ColorPicker>

	);
};
