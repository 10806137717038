import React, {useState, useRef, useEffect} from 'react';
import { Container, Header, Text, TextContainer, MagicColor } from "./style";
import { HexColorPicker } from "react-colorful";

type Props = {
	name: string
	onChange: (cor: string) => void
	color?: string
};

export const TextField: React.FC<Props> = (props: Props) => {

	const [picker, setPicker] = useState<boolean>(false)
	const [hashTag, setHashTag] = useState<boolean>(false)

	const handlerChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
		const inputValue = e.currentTarget.value.replace('#', '')
		if (/#/.test(e.currentTarget.value)) {
			setHashTag(true)
		} else {
			setHashTag(false)
		}
		props.onChange(inputValue)
	}

	const wrapperRef = useRef(null);
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			// @ts-ignore
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setPicker(!picker)
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, picker]);





	return (
		<Container>
			<Header>{props.name}</Header>
			<TextContainer>
				<Text placeholder={'FFFFFF'}
				      maxLength={hashTag ? 7 : 6}
				      value={props.color ? props.color : ""}
				      onChange={handlerChange} />
				{picker &&
						<div className="pick" ref={wrapperRef} >
							<HexColorPicker color={props.color ? hashTag ?  props.color : '#' + props.color : 'white'} onChange={e => props.onChange(e.replace('#', ''))} />
						</div>
				}
				<MagicColor color={props.color ? hashTag ?  props.color : '#' + props.color : 'white'} onClick={() => {
					setPicker(!picker)
				}}/>
			</TextContainer>
		</Container>
	);
};

