import {DefaultTheme} from "styled-components";

export const lightTheme: DefaultTheme = {
	title: 'light',
	background: "#EEF1F4",
	text: "#1D2630",
	color: {
		colorPickerBackground: "white",
		colorPickerMainText:  "white",
		colorPickerPlaceholder: "#1D2630",
		colorPickerHashtag:  "white",
		toogleBackground: '#FFFFFF',
		tooglePositive: '#1D2630',
	}
}

