import styled from "styled-components";

export const Container = styled.div`
	margin-top: 1rem;
`

export const Header = styled.div`
	font-weight: 500;
	color: #A8B4C1;
`

export const Text = styled.input`
	outline: 0;
	border: 0;
	text-transform: uppercase;
	font-size: 1rem;
  position: relative;
  background: transparent;
  font-weight: 700;
	color: ${({theme}) => theme.text};

  &::placeholder {
    color: ${({theme}) => theme.color.colorPickerPlaceholder};
		font-weight: 222;
	  opacity: .4;
	}

	
	
`

export const TextContainer = styled.div`
	display: flex;
  border: 2px solid ${({theme}) => theme.title === 'dark'? 'transparent': '#EEF1F4'};
	justify-content: space-between;
  padding: 1rem 1.5rem 1rem 3rem;
  width: 100%;
  border-radius: .5rem;
  margin-top: .3rem;
	position: relative;
  background: ${({theme}) => theme.color.colorPickerBackground};

  &::before {
    content: '#';
    font-size: 1.5rem;
    color: rgba(0,0,0,.8);
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 1.5rem;
	  font-weight: 400;
	  
  }

  .pick {
	 	z-index: 99;
		position: absolute;
	  top: 2.5rem;
	  right: -1rem;
  }
`
type MagicColorProps = {
	color?: string
}
export const MagicColor = styled.div.attrs( ({ color }) => ({
	style: {
		background: color ? color : 'white'
	},
}))<MagicColorProps>`
	position: absolute;
  top: 50%;
	right: .3rem;
  transform: translate(-50%, -50%);
	min-width: 1.7rem;
	height: 1.7rem;
  border-radius: .4rem;
	cursor:pointer;
  border: 3px solid white;
  box-shadow: 0 3px 6px 0 ${({theme}) => theme.title === 'dark' ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.08)'};

	
`
