import { createContext, useContext } from 'react'
import {ColorObject} from "../../models/color-object";

type ColorContext = {
	colorObject: ColorObject
	setColorObject: (colorObject: ColorObject) => void
}

const initalValues: ColorContext = {
	colorObject: {
		color_one: "B2F0F2",
		color_two: "001D2E",
	},

	setColorObject: colorObject => console.log("Must setColorObject")
}


export const ContextProvider = createContext<ColorContext>(initalValues)
export const useColor = () => useContext(ContextProvider);
