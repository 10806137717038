import React from 'react';
import styled from 'styled-components';

type Props = {
	status: boolean,
	setStatus?: () => void
}
const Container = styled.div<{status: boolean}>`
	display: inline-block;
	min-width: 2.5rem;
	min-height: 1.2rem;
	background: ${({theme}) => theme.color.toogleBackground};
	color: red;
	right: 1rem;
	border-radius: 1rem;
	position: absolute;
	
	.position {
		background: ${({theme}) => theme.color.tooglePositive};
		width: .8rem;
		height: .8rem;
    border-radius: 1rem;
		position: absolute;
		top: 50%;
		left: ${({status}) => status ? '.2rem' : '1.4rem'};
		transform: translate(0, -50%);
		cursor: pointer;
		transition: all ease-in-out .25s;
	}
`
export const Toogle: React.FC<Props> = ({status, setStatus}:Props) => {

	return (
		<Container status={status}>
			<div className="position right" onClick={() => setStatus ? setStatus() : null}>
			</div>
		</Container>
	);
};
