import {Color} from "../../../domain/usecases/GetContrast";

export class GetContrast {

	checker(color1: Color, color2: Color): string {
		const luminance = ({r,g,b}: Color): number => {
			let a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928
					? v / 12.92
					: Math.pow( (v + 0.055) / 1.055, 2.4 );
			});
			return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
		}

		let lum1 = luminance(color1);
		let lum2 = luminance(color2);
		let brightest = Math.max(lum1, lum2);
		let darkest = Math.min(lum1, lum2);
		let contrast = darkest > brightest ? ((darkest + 0.05) / (brightest + 0.05)) : ((brightest + 0.05) / (darkest + 0.05))
		return contrast.toPrecision(3)
	}

}
