import styled from "styled-components";

type Props = {
	color: string
	text_color: string
}
export const Grid = styled.div`
	margin-top: 1rem;
	display: grid;
	grid-gap: .1rem;
	background: ${({theme}) => theme.background};
	border-radius: 1rem;
	grid-template-areas:
		'L M'
		'L S';
	width: 100%;
  height: 100%;
	
  max-width: 28.125rem;
	

	
	@media (min-width: 900px ) {
    min-width: 31.875rem;
	}
`

export const Large = styled.div<Props>`
	grid-area: L;
  background: ${({ color }) => color};
	color: ${({ text_color }) => text_color};
  border-radius: 1rem 0 0 1rem;
	padding: 1rem;
	min-width: 9.5rem;
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }

  p {
    text-align: center;
	  font-size: 1.2rem;
  }
 
`

export const Medium = styled.div<Props>`
  grid-area: M;
  font-weight: bold;
  background: ${({ color }) => color};
  color: ${({ text_color }) => text_color};
  border-radius: 0 1rem 0 0;
  padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

`

export const Small = styled.div<Props>`
  grid-area: S;
  font-weight: bold;
  text-align: center;
  background: ${({ color }) => color};
  color: ${({ text_color }) => text_color};
  border-radius: 0 0 1rem 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

`
