import React, {useState} from 'react';
import styled from "styled-components";
import {HiArrowsExpand, IoCloseSharp} from "react-icons/all";

type Props = {
	color_one: string
	color_two: string
	fullscreen?: boolean
};


// @ts-ignore
const Container = styled.div.attrs( ({ color_two }) => ({
	style: {
		background: color_two
	},
}))<Props>`

  height: 100%;
	margin-top: ${({fullscreen}) => fullscreen ? '0' : '1rem'};
	border-radius: ${({fullscreen}) => fullscreen ? '0' : '1rem'};
  width:  ${({fullscreen}) => fullscreen ? '100vw' : null};
  min-height:  ${({fullscreen}) => fullscreen ? '100%' :null};
	padding: 1rem;
	color: ${({color_one}) => color_one};
	position: ${({fullscreen}) => fullscreen ? 'fixed' : 'relative'};
  top: ${({fullscreen}) => fullscreen ? 0 : null};
  left: ${({fullscreen}) => fullscreen ? 0 : null};
	transition: all ease-in-out 250ms;
  overflow: hidden;
	


	
	h4 {
		margin-top: 1rem;
		
	}
	
	.hover {
    position: relative;	
		display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({fullscreen}) => fullscreen ? 'center' : 'start'};
		height: ${({fullscreen}) => fullscreen ? '100vh' : '100%'};
		max-width: 50rem;
    margin: 0 auto;
    overflow: hidden;
		h1 {
      transform: scale(.9);
			margin-left: -1rem;
			text-align: start;
		}
		.lorem {
			display: ${({fullscreen}) => fullscreen ? 'flex' : 'none'};
			margin-top: 1rem;
			flex-direction: column;
      overflow: hidden;
			
			h3 {
        overflow: hidden;
				align-self: center;
				
			}
		}
	}
`

const Button = styled.button`
	position: absolute;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	border: 0;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,.10);
  right: 0;
	top:0;
  
	display: flex;
	align-items: center;
	justify-content: center;
  cursor: pointer;
	transition: all ease-in-out 250ms;
	.icon {
		margin-left: .08rem;
	}
	
	&:hover {
		opacity: .8;
	}
`
export const Example: React.FC<Props> = ({ color_one, color_two}: Props) => {
	const [fullScreen, setFullScreen] = useState(false);

	return (
		<Container  fullscreen={fullScreen} color_one={color_one} color_two={color_two}>
			<div className="hover">
				<Button onClick={() => {
					setFullScreen(!fullScreen)
				}}>
					{ fullScreen ?
						<IoCloseSharp className={'icon'} size={22} opacity={.5}/>
						:
						<HiArrowsExpand className={'icon'} size={22} opacity={.5}/>
					}
				</Button>
				<h1>Texto de Exemplo</h1>
				<h4>Para vc ter uma ideia</h4>
				<p>de como ficará o contraste visualmente</p>
				<div className="lorem ">
					<h3> Um pouco de Lorem Ipsum</h3>
					<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</p>
				</div>
			</div>
		</Container>
	);
};
