import styled from "styled-components";



export const ColorPicker = styled.div`
	max-width: 28.125rem;
	background: ${ ({theme}) => theme.color.colorPickerBackground};
	padding: 1rem;
	border-radius: 1rem;
	width: 100%;
	margin-top: 1rem;

`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
	width: 100%;
	
`

export const Button = styled.div`
	background: #0091FF;
	padding: .8rem 2rem;
	border-radius: .5rem;
	font-weight: bold;
	text-align: center;
  text-transform: uppercase;
	font-size: 1.1rem;
	margin-top: 2rem;
	color: white;
  cursor: pointer;
	transition: all ease-in-out 250ms;
	&:hover {
		opacity: .8;
	}
`
