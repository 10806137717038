import React from 'react';
import styled, { DefaultTheme, ThemeProvider} from 'styled-components'

import {darkTheme, lightTheme} from "../presenter/style/themes";
import GlobalStyle from '../presenter/style/Global'
import {usePersistentState} from "../presenter/hooks/usePersistentState";
import {Toogle} from "./components/Toogle";

const Container = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 100%;
	
	@media (min-width: 900px) {
    justify-content: center;
		margin-top: -6rem;
    
	}
`

const HeaderWrapper = styled.div`
  margin-top: 5rem;
  display: flex;
  max-width: 40rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
	position: relative;
`

const Header = styled.h1`
  font-weight: bold;
  font-size: 2.2rem;
	margin: 0 auto;
	@media (max-width: 500px) {
		margin: 0 auto 0 0;
    font-size: 1.5rem;
	}
`

type Props = {
	children: React.ReactNode
}

export const Theme: React.FC<Props> = ({children}: Props ) => {

	const [theme, setTheme] = usePersistentState<DefaultTheme>('theme', lightTheme)

	function toogleTheme() {
		theme.title === 'light' ? setTheme(darkTheme) : setTheme(lightTheme)
	}

	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<Container>
					<Wrapper>
						<HeaderWrapper>
							<Header>Contrast Checker</Header>
							<Toogle status={theme.title !== 'light'} setStatus={toogleTheme} />
						</HeaderWrapper>
							{ children }
					</Wrapper>
				</Container>
			</>
		</ThemeProvider>
	);
};
