import React from 'react';
import { Grid, Large, Medium, Small } from "./style";

type Props = {
	contrast: string
};


export const Ratings: React.FC<Props> = ({ contrast }: Props) => {

	const getContrastLegenda = () => {
		const value = parseInt(contrast)
		switch(true) {
			case (2 > value): return { color: "#FBD0DA", text: "#5F071C", stars: "Muito Ruim!"}
			case (2 <= value && value < 4.5): return { color: "#FBD0DA", text: "#5F071C", stars: "Ruim..."}
			case (4.5 <= value && value < 7): return { color: "#FBF5D0", text: "#5F5207", stars: "Normal"}
			case (7 <= value && value < 12): return { color: "#D2FBD0", text: "#0D5F07", stars: "Muito Bom!"}
			default: return  { color: "#D2FBD0", text: "#0D5F07", stars: "Excelente!"}
		}
	}

	const getLargeColor = () => {
		const value = parseInt(contrast)
		switch(true) {
			case (3 > value): return { color: "#FBD0DA", text: "#5F071C", stars: 1}
			case (3<= value && value < 4.5): return { color: "#FBF5D0", text: "#5F5207", stars: 2}
			default: return  { color: "#D2FBD0", text: "#0D5F07", stars: 3}
		}
	}

	const getMediumColor = () => {
		const value = parseInt(contrast)
		switch(true) {
			case (4.5 > value): return { color: "#FBD0DA", text: "#5F071C", stars: 1}
			case (4.5 <= value && value < 7): return { color: "#FBF5D0", text: "#5F5207", stars: 2}
			default: return  { color: "#D2FBD0", text: "#0D5F07", stars: 3}
		}
	}

	return (
		<Grid>
			<Large color={getContrastLegenda().color} text_color={getContrastLegenda().text}>
				<p>{getContrastLegenda().stars}</p>
				<h1>{contrast}</h1>
			</Large>
			<Medium color={getLargeColor().color} text_color={getLargeColor().text}>
					<div>Textos Grandes</div>
			</Medium>
			<Small color={getMediumColor().color} text_color={getMediumColor().text}>
				<div>Textos Pequenos</div>
			</Small>
		</Grid>
	);
};
